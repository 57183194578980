<div style="margin-top: 2rem;" class="nav-validation" *ngIf="(!doneAt && validate) || (type === 'selfassessment' && validate)">
    <button class="btn" id="btn-course" (click)="activityFinished()">
        <div style="display: flex; justify-content: center;">
            <div class="lds-ring" *ngIf="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <!-- <span>
                {{ ((display) ? 'player.footer.next-section.description' + translator : 'player.common.ended-activity') | translate }}
            </span> -->

            <ng-container *ngIf="display; else endAtivityTemplate" (click)="refreshData()">
                <span>{{ ((type === 'project' || type === 'selfassessment') ? 'player.common.submit-activity' : 'player.common.continue-activity') | translate }}</span>
            </ng-container>

            <ng-template #endActivityTemplate>
                <span>{{ 'player.common.ended-activity' | translate }}</span>
            </ng-template>
        </div>
    </button>
</div>

<!-- <div class="nav-validation__btn" *ngIf="display">
    <button class="btn btn--prev" (click)="goNextOrPrev('prev')">
        <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_long_arrow_left_grey.svg" alt="icon previous section">
        {{ 'word.previous' | translate }}
    </button>

    <button *ngIf="nextAvailable || doneAt && !lastQuestion && currentSectionIsCompleted" class="btn btn--link btn--next" (click)="goNextOrPrev('next')">
        {{ 'word.next' | translate }} <img src="https://static.myskillcamp.com/images/icon/icon-camp/icon_long_arrow_blue.svg" alt="icon next section">
    </button>
</div> -->
