import { Component, OnInit } from '@angular/core';
import { EmitterService } from '../../services/emitter.service';
import { NavigationProvider } from '../../services/navigation.provider';
import { StorageService } from '../../services/storage.service';
import { CourseSubscriptionSessionProvider } from '@stuplay';
import { take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'msc-next-prev-nav',
    templateUrl: './next-prev-nav.component.html',
    styleUrls:['./next-prev-nav.component.scss']
})
export class MscNextNavComponent implements OnInit {
    isOrdered: boolean = false;
    display: boolean = true;
    course: any;
    disabled: boolean;

    constructor(
        public navigation: NavigationProvider,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider,
        private router: Router
    ) { }

    ngOnInit() {
        this.emitterService.get('sectionOpen').subscribe(() => {
            this.isNextSectionDisabled();
        });
        
        this.course = this.storageService.get('course');
        this.isOrdered = this.course.isOrdered;
        this.display = !this.course.isTrajectContent || (this.course.isTrajectContent && this.course.templateId);
    }

    goPrev() {
        this.scroller();
        this.navigation.goRoute('prev');
        const currentRoute = this.router.url;
        if (!currentRoute.includes('question')) {
            this.courseSubscriptionSessionProvider.getOrCreateCourseSubscriptionSession(this.course.id).pipe(take(1)).subscribe((data) => {
                console.log(data)
            });
        }
        this.isNextSectionDisabled();
    }

    goNext() {
        this.scroller();
        this.navigation.goRoute('next');
        const currentRoute = this.router.url;
        if (!currentRoute.includes('question')) {
            this.courseSubscriptionSessionProvider.getOrCreateCourseSubscriptionSession(this.course.id).pipe(take(1)).subscribe();
        }
        this.isNextSectionDisabled();
    }

    scroller(): void {
        if (!document?.getElementById('view')?.scroll) { return; }
        document.getElementById('view').scroll(0, 0);
    }

    isNextSectionDisabled(): void {
        this.disabled = this.navigation.isLastSectionContent() && !this.navigation.isNextSectionAvailable()
    }
}
