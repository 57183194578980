import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '../../utils/services/translation.service';
import { Course, CourseSubscriptionSession } from '@stuplay';
import { StorageService } from '../../utils/services/storage.service';
import { ExternalAppsService } from '../../utils/services/external-apps.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UrlHelper } from 'src/app/utils/helpers';

@Component({
    selector: 'msc-course',
    templateUrl: './course.component.html'
})
export class CourseComponent implements OnInit {
    public course: Course | any;
    public courseSubscriptionSession: CourseSubscriptionSession;
    public account: any;
    public showSidebar: boolean = false;
    public displays: any = {
        topbar: true,
        sidebar: true
    };
    public external: boolean = false;
    public company: any;

    constructor(
        private route: ActivatedRoute,
        private translationService: TranslationService,
        private storageService: StorageService,
        private cookieService: CookieService,
        private externalAppsService: ExternalAppsService,
    ) { }

    ngOnInit() {
        this.account = this.storageService.get('me');
        this.course = this.storageService.get('course');
        this.company = this.storageService.get('company');
        this.courseSubscriptionSession = this.route.snapshot.data.courseSubscriptionSession;
        this.translationService.setLang(this.account.language.code);
        this.storageService.set('referrer', document.referrer);
        this.displays.sidebar = !this.course.isTrajectContent || (this.course.isTrajectContent && this.course.templateId);
        this.route.queryParams.subscribe((params) => {
            if (params.external || params.direct_play) {
                this.external = params.external === 'true' || params.direct_play === 'true';
                this.displays.topbar = !this.external;
            }

            if (params.company_id) {
                this.cookieService.set('current_company', params.company_id, 10, '/', UrlHelper.getDomain(), false, 'Lax');
            }
        });

        this.externalAppsService.boot(this.account);
        //select bubble chat to show or hidde
        if(this.company.id === 520 && this.course.id === 935664) {
            setTimeout(() => {
                document.getElementById('chatbase-bubble-button').style.setProperty('display', 'block', 'important');

            }, 1000);
        } else {
            document.getElementById('chatbase-bubble-button').style.setProperty('display', 'none', 'important');

        }
    }

    toggleSidebar() {
        this.showSidebar = !this.showSidebar
    }
}
