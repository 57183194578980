import { Component, EventEmitter, Input, Output, OnInit, DoCheck } from '@angular/core';
import { NavigationProvider } from '../../services/navigation.provider';
import { StatsService } from '../../services/stats.service';
import { StorageService } from '../../services/storage.service';
import { CourseSubscriptionSessionProvider } from '@stuplay';
import { take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'msc-next-prev-btn',
    templateUrl: './next-prev-btn.component.html'
})
export class MscNextPrevComponent implements OnInit, DoCheck {
    @Input() validate: boolean = false;
    @Input() translator: string = '';
    @Input() doneAt: any;
    @Input() lastQuestion: boolean = false;
    @Input() navigate: boolean = true;
    @Input() type: string = 'default';
    @Input() loading: boolean = false;
    @Input() navigateEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() isFinished: EventEmitter<boolean> = new EventEmitter();
    currentSectionIsCompleted: boolean = false;
    course: any;
    nextAvailable: boolean = true;
    display: boolean = true;

    constructor(
        private navigationProvider: NavigationProvider, 
        private statsService: StatsService, 
        private storageService: StorageService, 
        private courseSubscriptionSessionProvider: CourseSubscriptionSessionProvider,
        private router: Router
    ) { }

    ngOnInit() {
        this.course = this.storageService.get('course');
        this.display = !this.course.isTrajectContent || (this.course.isTrajectContent && this.course.templateId) || this.course.sections[0].sectionContents[0].context === 'exercise';

        this.currentSectionIsCompleted = this.navigationProvider.currentCourseSubscriptionSession.getSectionProgress(this.navigationProvider.currentSection) === 1;

        this.navigateEmitter.subscribe(() => {
            this.navigation();
        });
    }

    goNextOrPrev(direction: string) {
        if (!document?.getElementById('view')?.scroll) { return; }
        document.getElementById('view').scroll(0, 0);
        this.navigationProvider.goRoute(direction);
    }

    ngDoCheck() {
        this.nextAvailable = this.navigationProvider.isNextSectionAvailable();
    }

    activityFinished() {
        if (!this.loading) {
            this.isFinished.emit(true);

            if (this.navigate) {
                this.process();
            }
        }
    }

    process(): void {
        if (this.display) {
            const timeOut = setTimeout(() => {
                this.navigation();
                clearTimeout(timeOut);
            }, 1500);
        } else {
            const timeOut = setTimeout(() => {
                window.location.href = this.storageService.getFlash('referrer');
                clearTimeout(timeOut);
            }, 3000);
        }
    }

    navigation(): void {
        this.statsService.refresh(true);
        if (this.navigationProvider.isNextSectionAvailable()) {
            this.goNextOrPrev('next');
        }
    }

    public refreshData(): void {
        const currentRoute = this.router.url;
        if (!currentRoute.includes('question')) {
            this.courseSubscriptionSessionProvider.getOrCreateCourseSubscriptionSession(this.course.id).pipe(take(1)).subscribe();
        }
    }
}
